:root {
  --default-color: #ffffff;
  --muted-color: #f8f8f8;
  --primary-color: #20CAE6;
  --secondary-color: #cccccc;

  --body-font-size: 16px;
  --body-line-height: 1.1;
  --body-font-weight: normal;
  --body-text-color: var(--secondary-color);
  --body-background-color: #FBFBFD;
  --body-background-size:400px 182px;
  --body-background-repeat: repeat; //no-repeat;
  --body-fonts: "Helvetica", "Arial", sans-serif;
  --body-background-image: //none;

  --a-text-color: var(--primary-color);

  --bx-header-display:flex;
  --bx-header-height:30px;
  --bx-header-padding:5px 10px;
  --bx-header-width:1200px;
  --bx-header-bg: transparent;
  --bx-header-text-color: var(--default-color);

  --switchLang-display:block;
  --switchLang-border-radius: 5px;
  --switchLang-bg-color: var(--primary-color);
  --switchLang-text-color: var(--default-color);

  --bx-header-logo-display: block;
  --bx-header-logo-width:100px;

//  --bx-container-bg: url("../src/assets/imgs/bg.png"); 

  --creative-img-width: 120px;
  --bx-creative-min-height: 250px;
  --bx-creative-justify-content: center;
 
  --main-title-font-size: 28px;
  --main-title-text-color: #222222;

  --sub-title-font-size: 18px;
  --sub-title-text-color: #222222;

  --label-font-size: 18px;
  --label-font-weight: bold;
  --label-text-color: #222222;
 
  --mo-p-font-size: 16px;
  --mo-p-text-color: #222222;

  --mo-b-font-size: 18px;
  --mo-b-text-color: var(--primary-color);

  --subArea-padding: 50px 30px;
  --subArea-border-radius: 8px;
  --subArea-bg-color: var(--default-color);
  --subArea-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);

  --credit-input-border:1px solid var(--primary-color);;
  --credit-input-border-radius:2px;
  --credit-input-font-size:16px;
  --credit-input-font-weight:normal;

  --credit-input-placeholder-text-color: #ccc;
  --credit-input-placeholder-font-size: 16px;
  --credit-input-placeholder-font-weight: normal;
   
  --phoneInputWrap-font-size: 22px;
  --phoneInputWrap-font-weight: bold;

  --phoneInputWrap-border-radius: 30px;
  --phoneInputWrap-border: 2px solid var(--primary-color);

  --phoneInputWrap-placeholder-font-size:13px;
  --phoneInputWrap-placeholder-text-color: #ccc;
  --phoneInputWrap-placeholder-font-weight: normal;

  --pinInput-font-size: 20px;
  --pinInput-border-radius: 8px;
  --pinInput-border: 3px solid var(--primary-color);
  --pinInput-background: rgba(239, 239, 239, 0.83);
  --pinInput-box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25), 0 1px 0 #fff;

  --pinInput-placeholder-color: #ccc;
  --pinInput-placeholder-font-size: 20px;
  --pinInput-placeholder-font-weight: normal;

  --button-min-height: 55px;
  --button-font-size: large;
  --button-font-weight: bold;
  --button-border-radius: 50px;
  --button-text-color: var(--default-color);
  --button-bg-color: var(--primary-color);


  --button-disabled-text-color: #777;
  --button-disabled-bg-color:var(--muted-color);

  --bx-msg-font-size:14px;

  --top-legals-font-size:11px;
  --top-legals-text-color: var(--secondary-color);

  --price-point-font-size:12px;
  --price-point-text-color: var(--secondary-color);
  
  --disclaimer-font-size: 12px;
  --disclaimer-max-width: 640px;
  --disclaimer-text-align: justify;
  --disclaimer-text-color: var(--secondary-color);
  --disclaimer-a-decoration: underline;

  --bx-overlay-modal-text-color: #ccc;
  --bx-overlay-modal-backdrop-filter: blur(1.5px);
  --bx-overlay-modal-bg-color: rgba(0, 0, 0, 0.75);

  --bx-modal-elements-top: -150px;

  --bx-spinner-color: var(--primary-color);
}
