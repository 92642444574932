* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  -webkit-touch-callout: none;
  // color: var(--body-text-color);
  font-family: var(--body-fonts);
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  background: var(--body-background-color);
}

body {
  margin: 0;
  min-height: 100vh;
  padding: 0 0 50px 0;
  // font-size: var(--body-font-size);
  // line-height: var(--body-line-height);
  // font-weight: var(--body-font-weight);
  // background-size: var(--body-background-size);
  // background-repeat: var(--body-background-repeat);
  // background-image: var(--body-background-image);
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p {
//   margin: 0;
//   padding: 0;
//   line-height: 1.5em;
// }

a {

  cursor: pointer;
  text-decoration: none;
  // color: var(--a-text-color);

  &:hover {
    text-decoration: underline;
  }

}

.bx-ar {
  direction: rtl;
  text-align: right;
}

.mainTitle {
  // color: var(--main-title-text-color);
  // font-size: var(--main-title-font-size);
}

.subTitle {
  // color: var(--sub-title-text-color);
  // font-size: var(--sub-title-font-size);
}

.moInstructions {

  p {
    // color: var(--mo-p-text-color);
    // font-size: var(--mo-p-font-size);

    b {
      // color: var(--mo-b-text-color);
      // font-size: var(--mo-b-font-size);
    }

  }

}

.oc2smsLabel,
.msisdnLabel,
.pinEntryLabel,
.moLabel,
.clickLabel {
  max-width: 300px;
  margin: 10px auto;
  text-align: center;
  // color: var(--label-text-color);
  // font-size: var(--label-font-size);
  font-weight: var(--label-font-weight);
}

.bx-header {
  direction: ltr;
  // margin: 0 auto;
  // padding: var(--bx-header-padding);
  // min-height: var(--bx-header-height);
  // max-width: var(--bx-header-width);
  // position: relative;
  // align-items: center;
  // justify-content: space-between;
  // background: var(--bx-header-bg);
  // display: var(--bx-header-display);
  // color: var(--bx-header-text-color);

  .header-logo {
    display: var(--bx-header-logo-display);
    margin: 0;
    width: var(--bx-header-logo-width);
  }

  .switchLang {
    position: relative;
    display: var(--switchLang-display);
    margin: 0;
    padding: 5px 10px;
    max-width: fit-content;
    min-height: unset;
    background: var(--switchLang-bg-color);
    border-radius: var(--switchLang-border-radius);
    // color: var(--switchLang-text-color);
    // font-size: 14px;
    outline: unset;
    z-index: 2;
    
    &:hover {
      opacity: 0.85;
    }
  }
}

.bx-container {
  display: flex;
  padding: 0 10px;
  margin: 0px auto;
  max-width: 1180px;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-size: 1200px auto;
  background-repeat: no-repeat;
  background-position: top center;
  // background-image: var(--bx-container-bg);
}

.bx-creative {
  width: 100%;
  display: flex;
  max-width: 360px;
  position: relative;
  align-items: center;
  flex-direction: column;
  min-height: var(--bx-creative-min-height);
  justify-content: var(--bx-creative-justify-content);


  .creative-img {
    margin: 20px 0;
    width: var(--creative-img-width);
  }

}

.bx-holder {
  width: 100%;
  max-width: 360px;
  text-align: center;
}

.subscriptionArea {
  display: block;
  text-align: center;
  margin-bottom: 10px;
  padding: var(--subArea-padding);
  background: var(--subArea-bg-color);
  box-shadow: var(--subArea-box-shadow);
  border-radius: var(--subArea-border-radius);

  &:hover {
    text-decoration: none !important;
  }

}

.with-prelander-false {
  display: none;
}

.credit-area {

  input {
    width: 270px;
    padding: 10px;
    margin: 5px auto;
    background-color: #fff;
    border: var(--credit-input-border);
    border-radius: var(--credit-input-border-radius);

    font-size: var(--credit-input-font-size);
    font-weight: var(--credit-input-font-weight);

    &::placeholder {
      // color: var(--credit-input-placeholder-text-color);
      // font-size: var(--credit-input-placeholder-font-size);
      font-weight: var(--credit-input-placeholder-font-weight);
    }

  }

}

.phoneInputWrap {
  direction: ltr;
  margin: 10px auto;
  background-color: #fff;
  border: var(--phoneInputWrap-border);
  border-radius: var(--phoneInputWrap-border-radius);

  .checkmark-container {
    margin-right: 5px;
  }

  input {
    // font-size: var(--phoneInputWrap-font-size);
    // font-weight: var(--phoneInputWrap-font-weight);

    &::placeholder {
      // color: var(--phoneInputWrap-placeholder-text-color);
      // font-size: var(--phoneInputWrap-placeholder-font-size);
      // font-weight: var(--phoneInputWrap-placeholder-font-weight);
    }

  }

}

.pinInputWrap {
  max-width: 240px;
  margin: 10px auto;
  position: relative;

  &::after {
    content: "";
    top: 12px;
    left: 15px;
    width: 40px;
    height: 40px;
    opacity: 0.5;
    display: block;
    position: absolute;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNNDMyIDMyN0g4MGMtNDQuMTEyIDAtODAgMzUuODg4LTgwIDgwczM1Ljg4OCA4MCA4MCA4MGgzNTJjNDQuMTEyIDAgODAtMzUuODg4IDgwLTgwcy0zNS44ODgtODAtODAtODB6TTEwNiA0MzJjLTEzLjgwNyAwLTI1LTExLjE5My0yNS0yNXMxMS4xOTMtMjUgMjUtMjUgMjUgMTEuMTkzIDI1IDI1LTExLjE5MyAyNS0yNSAyNXptMTAwIDBjLTEzLjgwNyAwLTI1LTExLjE5My0yNS0yNXMxMS4xOTMtMjUgMjUtMjUgMjUgMTEuMTkzIDI1IDI1LTExLjE5MyAyNS0yNSAyNXptMTAwIDBjLTEzLjgwNyAwLTI1LTExLjE5My0yNS0yNXMxMS4xOTMtMjUgMjUtMjUgMjUgMTEuMTkzIDI1IDI1LTExLjE5MyAyNS0yNSAyNXptMTAwIDBjLTEzLjgwNyAwLTI1LTExLjE5My0yNS0yNXMxMS4xOTMtMjUgMjUtMjUgMjUgMTEuMTkzIDI1IDI1LTExLjE5MyAyNS0yNSAyNXptLTU1LTMwNWgtMjB2LTI3YzAtNDEuMzU1LTMzLjY0NS03NS03NS03NXMtNzUgMzMuNjQ1LTc1IDc1djI3aC0yMGMtOC4yODQgMC0xNSA2LjcxNi0xNSAxNXYxMTljMCA4LjI4NCA2LjcxNiAxNSAxNSAxNWgxOTBjOC4yODQgMCAxNS02LjcxNiAxNS0xNVYxNDJjMC04LjI4NC02LjcxNi0xNS0xNS0xNXptLTgwIDc5LjEyOFYyMTJjMCA4LjI4NC02LjcxNiAxNS0xNSAxNXMtMTUtNi43MTYtMTUtMTV2LTUuODcyYy01LjgyMi0yLjA2MS0xMC03LjYtMTAtMTQuMTI4IDAtOC4yODQgNi43MTYtMTUgMTUtMTVoMjBjOC4yODQgMCAxNSA2LjcxNiAxNSAxNSAwIDYuNTI4LTQuMTc4IDEyLjA2Ny0xMCAxNC4xMjh6TTMwMSAxMjdoLTkwdi0yN2MwLTI0LjgxMyAyMC4xODctNDUgNDUtNDVzNDUgMjAuMTg3IDQ1IDQ1djI3eiIvPjwvc3ZnPg==) no-repeat center;
  }

  .pinInput {
    padding: 8px;
    height: 40px;
    max-width: 220px;
    font-weight: bold;
    text-align: center;
    // font-size: var(--pinInput-font-size);
    border-radius: var(--pinInput-border-radius);
    border: var(--pinInput-border);
    background: var(--pinInput-background);
    box-shadow: var(--pinInput-box-shadow);

    &::placeholder {
      // color: var(--pinInput-placeholder-color); //#ccc;
      // font-size: var(--pinInput-placeholder-font-size); //20px;
      font-weight: var(--pinInput-placeholder-font-weight); //normal;
    }
  }

}

.tryAgainLink {
  z-index: 2;
  position: relative;
}

button,
.button,
.btn {
  width: 100%;
  border: none;
  display: flex;
  cursor: pointer;
  margin: 10px auto;
  align-items: center;
  justify-content: center;
  // color: var(--button-text-color);
  background: var(--button-bg-color);
  min-height: var(--button-min-height);
  // font-size: var(--button-font-size);
  // font-weight: var(--button-font-weight);
  // border-radius: var(--button-border-radius);

  &:disabled {
    color: var(--button-disabled-text-color);
    background: var(--button-disabled-bg-color);
  }
}

button {
  padding: 10px;
}

.legalArea {
  text-align: center;
}

.top-legals,
.price-point {
  position: relative;
}

.top-legals {
  margin: 0;
  // color: var(--top-legals-text-color);
  // font-size: var(--top-legals-font-size);
}

.price-point {
  // color: var(--price-point-text-color);
  // font-size: var(--price-point-font-size);
}

.disclaimer {
  z-index: 2;
  padding: 10px;
  margin: 0 auto;
  position: relative;
  // color: var(--disclaimer-text-color);
  // font-size: var(--disclaimer-font-size);
  max-width: var(--disclaimer-max-width);
  text-align: var(--disclaimer-text-align);


  a {
    text-decoration: var(--disclaimer-a-decoration);
  }

}

.msg,
.errorMsg,
.successMsg {
  margin: 10px 0;
  padding: 5px 10px;
  font-size: var(--bx-msg-font-size);
}

.msg {
  color: #1e87f0;
  background-color: #d8eafc;
}

.errorMsg {
  color: #F0506F;
  background-color: #fef4f6;
}

.successMsg {
  color: #32d296;
  background-color: #edfbf6;
}

.bx-overlay-true {

  .subscriptionArea {
    z-index: 2;
    position: relative;
    animation-name: bx-shakeX;
    animation-duration: 0.75s;
    animation-fill-mode: both;
  }

  .price-point {
    // z-index: 2;
    // color: var(--bx-overlay-modal-text-color);
  }

  .disclaimer {
    // color: var(--bx-overlay-modal-text-color);
  }


  &::before {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    position: fixed;
    background-color: var(--bx-overlay-modal-bg-color);
    backdrop-filter: var(--bx-overlay-modal-backdrop-filter);
  }

}

.bx-modal-true {

  .subscriptionArea {
    z-index: 2;
    top: var(--bx-modal-elements-top);
    position: relative;
  }

  .tryAgainLink {
    top: var(--bx-modal-elements-top);
  }

  .price-point {
    z-index: 2;
    // color: var(--bx-overlay-modal-text-color);
    top: var(--bx-modal-elements-top);
  }

  .disclaimer {
    // color: var(--bx-overlay-modal-text-color);
    top: var(--bx-modal-elements-top);
  }

  &::before {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    position: fixed;
    backdrop-filter: var(--bx-overlay-modal-backdrop-filter);
    background-color: var(--bx-overlay-modal-bg-color);
  }

}

.bx-spinner {
  width: 80px;
  height: 80px;
  // color: var(--bx-spinner-color);
  position: relative;
  transform: scale(0.75);
  display: inline-block;

  div {
    transform-origin: 40px 40px;
    animation: bx-spinner 1.2s linear infinite;

    &::after {
      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 37px;
      width: 6px;
      height: 18px;
      border-radius: 20%;
      background: var(--bx-spinner-color);
    }


    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }

    &:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }

    &:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }

    &:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }

    &:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }

    &:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }

    &:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }

    &:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }

    &:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }

    &:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }

    &:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }

    &:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }


  }


}

.preflowbtn>* {
  pointer-events: none;
}

.dcbp>* {
  pointer-events: none;
}

.bx-guides>div,
.bx-guides * div {
  outline: 1px dotted red;
}

.show {
  display: block;
}

.hide {
  display: none;
}

@media only screen and (max-width : 320px) {

  .subscriptionArea {
    padding: 50px 15px;
  }

}

@keyframes bx-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes bx-shakeX {

  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}