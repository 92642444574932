@import url('https://fonts.googleapis.com/css2?family=Monda:wght@700&display=swap');

$black : #00001C;
$white : white;
$pcolor: #20CAE6;
$pfont: "Monda", sans-serif;
$sfont: "Helvetica", "Arial", sans-serif;

// general styles
html, body{
  max-width: 100dvw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
body{
  background-color: $black;
  color: $white;
  font-family: $sfont;
  font-size: 16px;
  line-height: 1.1;
}

.container{
  width: 950px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  padding: 0 15px;
  @media screen and (max-width: 575px) {
    padding: 0 10px;
  }
}

h1,h2,h3,h4,h5,h6, .btn, button, .switchLang{
  font-family: $pfont;
  font-weight: 700;
}

h1{ font-size: 45px; text-transform: uppercase;}
h2{ font-size: 38px;}
h3{ font-size: 32px;}
h4{ font-size: 24px;}
h5{ font-size: 18px;}
h6{ font-size: 16px;}

a{ color: initial;}
.btn, button{
  font-size: 18px;
  min-height: 40px;
  display: inline-block;
  width: auto;
  color: white;
  position: relative;
  text-transform: uppercase;
  text-shadow: 0 0 4px rgba(0,0,0,.5);
  &::before, &::after{
    content:"";
    background-image: url("../src/assets/imgs/btn-right.svg");
    width: 16px;
    height: 100%;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
  }
  &::before{
    right: 100%;
    margin-right: -1px;
    transform: rotate(180deg);
  }
  &::after{
    left: 100%;
    margin-left: -1px;
  }
}

@media screen and (max-width: 575px) {
  .for-desktop{
    display: none !important;
  }
}
@media screen and (min-width: 576px) {
  .for-mobile{
    display: none !important;
  }
}
.hide{
  display: none !important;
}



//header
.bx-header{
  background:transparent;
  position: relative;
  z-index: 2;
  .container{
    display: flex;
    align-items: center;
    position: relative;
    height: 60px;
    justify-content: space-between;
    @media screen and (max-width: 575px){
      justify-content: flex-start;
      flex-direction: row-reverse;
    }
  }
  .brand{
    height: 22px;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    
    @media screen and (min-width: 576px){
      transform: translate(-50%, -50%);
      left: 50%;
    }
  }
  .switchLang, .btn, button{
    font-size: 16px;
    height: 30px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 1;
    border: 1px solid $white;
    background-color: transparent;
    margin: 0;
    padding: 0 8px;
    width: auto;
    color: white;
    border-radius: 0;
    min-height: auto;
    text-transform: capitalize;
    &::before, &::after{
      content:none;
    }
  }
  .switchLang{
    text-align: left;
    // -webkit-appearance: none;
    // -moz-appearance: none;
    padding-left: 28px;
    background-image: url("../src/assets/imgs/globe.svg"); 
    background-size: 20px auto;
    background-position: 3px 4px;
    background-repeat: no-repeat;
    max-width: 30dvw;
    overflow: hidden;
    width: 120px;
    @media screen and (max-width: 575px){
      max-width: 40px;
      margin-left: 8px;
    }
    &:hover{
      opacity: 1;
    }
  }
}

//main
main{
  text-align: center;
  section{
    width: 100dvw;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    @media screen and (max-width: 575px){
      background-size: auto 80dvh;
    }
    .container{
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      min-height: 100dvh;
      flex-direction: column;
      padding-top: 60px;
      padding-bottom: 60px;
    }
    h1{ margin-bottom: 20px;}
    p{
      margin: 0 auto 30px;
      width: 800px;
      max-width: 100%;
      font-size: 18px;
      + p{ margin-top: -20px;}
    }
    &.sec1{
      background-image: url("../src/assets/imgs/bg-sec1.png");
      margin-top: -60px;
      .price-point{
        font-size: 12px;
        letter-spacing: 1px;
        margin-top: 15px;
      }
      
      .aniScroll {
        display: block;
        position: absolute;
        bottom: 30px;
        .mousey {
          width: 3px;
          padding: 10px 15px;
          height: 35px;
          border: 2px solid $pcolor;
          border-radius: 25px;
          box-sizing: content-box;
        }
        .scroller {
          width: 3px;
          height: 10px;
          border-radius: 25%;
          background-color: #fff;
          animation-name: scroll;
          animation-duration: 2.2s;
          animation-timing-function: cubic-bezier(.15,.41,.69,.94);
          animation-iteration-count: infinite;
        }
        @keyframes scroll {
          0% { opacity: 0; }
          10% { transform: translateY(0); opacity: 1; }
          100% { transform: translateY(15px); opacity: 0;}
        }
      }
    }
    &.sec2{
      .catWrap{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        .each{
          width: 33.333%;
          position: relative;
          z-index: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          align-content: flex-start;
          @media screen and (max-width: 575px){
            width: 50%;
          }
          img{
            width: 128px;
            max-width: 100%;
            margin:auto
          }
          .catName{
            position: relative;
            text-transform: uppercase;
            width: 100%;
            &::before{
              content:"";
              height: 150px;
              width: 75%;
              display: block;
              position: absolute;
              z-index: -1;
              top: calc(-150px - 10px);
              left: 50%;
              transform: translateX(-50%);
              background-image: url("../src/assets/imgs/catbg.png");
              background-size: 100% auto;
              background-repeat: no-repeat;
              background-position: bottom center;
            }
            @media screen and (max-width: 575px){
              font-size: 16px;
              &::before{
                width: 95%;
              }
            }
          }
        }
      }
    }
    &.sec3{
      background-color: #01083C;
      background-attachment: fixed;
      background-image: url("../src/assets/imgs/bg-sec3.jpg");
      img{
        width: 500px;
        max-width: 100%;
      }
    }
    &.sec4{
      padding: 50px 0;
      .container{
        min-height: auto;
      }
      h2{
        margin: 0 auto 15px;
      }
      img{
        height: 20px;
        margin-bottom: 30px;
      }
    }
  }
}

//footer
footer, #footer{
  font-size: 12px;
  padding: 30px 0 100px;
  a{
    color: white;
  }
  .footer-top{
    .container{
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 575px){
        flex-direction: column;  
        justify-content: flex-start;
      }
    }
    h6{ margin: 15px 0 5px;}
    .customer-care{
      .list-wrap{
        margin-top: 1em;
        .each{
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          padding-left: 15px;
          position: relative;
          + .each{
            margin-top: 8px;
          }
          img{
            position: absolute;
            width: 12px;
            left: 0;
            top: 0;
          }
        }
      }
    }
  }
  .footer-bottom{
    border-top: 1px solid rgba(255,255,255,.1);
    margin-top: 30px;
    padding-top: 30px;
    .container{
      display: flex;
      justify-content: space-between;
      align-items: center;

    }
    .credit-card-icon{
      display: flex;
    }
    img{
      height: 30px;
      +img{
        margin-left: 8px;
      }
    }
    .each{
      + .each{
        &::before{
          content:".";
          margin: 0 5px;
        }
      }
    }
  }
}

section.payment-page{
	display: block;
	width: 100dvw;
	height: 100dvh;
	background-color: rgba(0,0,28,.5);
	position: fixed;
	left: 0;
	top: 0;
	z-index: 999;
	overflow-y: scroll;
	color: white;
	visibility: hidden;
  opacity: 0;
  transition: all 0.35s ease-in;
	&.is-visible {
		visibility: visible;
		opacity: 1;
	}
  h4{
    margin: 0 0 8px;
  }
  p{
    margin: 0;
    font-size: 14px;
    +p{
      margin-top: 5px;
    }
  }
  .pp-close{
    width: 50px;
    height: 50px;
    display: flex;
    border-radius: 50%;
    border:2px solid rgba(255,255,255,.5);
    position: absolute;
    right: 15px;
    top: 15px;
    justify-content: center;
    align-items: center;
    transition: .3s;
    background-color: transparent;
    margin: 0;
    &::before, &::after{
      content:none;
    }
    svg{
      width: 20px;
      height: 20px;
      path{
        fill: white;
      }
    }
    &:hover{
      background-color: white;
      svg path {fill: $black;}
    }
  }
  .pp-header{
    padding: 30px 0 30px;
  }
  img.pp-logo{
    width: 250px;
  }
  .pp-wrap{
    display: flex;
    justify-content: space-between;
    .pp-left, .pp-right{
      width: calc(50% - 15px);
    }
    .pp-right{
      .each{
        + .each{ margin-top: 15px;}
      }
    }
    @media screen and (max-width: 575px){
      width: calc(200dvw);
      max-width: none;
    }
  }
  #footer{
    margin-top: 80px;
  }
  form{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-top: 30px;
    .form-group{
      +.form-group{
        margin-top: 15px;
      }
      &.for-checkbox{
        margin-top: 30px;
        + .for-checkbox{
          margin-top: 15px;
        }
      }
    }
    label.form-label{
      font-family: $sfont;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 2px;
      display: flex;
      +div[class*="Input-module"]{
        display: flex;
        flex-direction: column-reverse;
      }
    }
    .form-control{
      display: block;
      width: 100%;
      height: 40px;
      padding: 5px;
      color: $black;
      border: 2px solid transparent;
      outline: none;
      &:focus, &:focus-visible{
        border-color: $pcolor;
      }
      & + div[class*="error"]{
        color: #fef4f6;
        background-color: #F0506F;
        font-weight: bold;
        padding: 4px 5px;
        line-height: 1;
        font-size: 11px;
        text-transform: lowercase;
      }
      &:not(:last-child){
        border-color: #fef4f6;
        border-top:0;
        height: 30px;
      }
    }
    label.checkbox-wrap{
      display: flex;
      align-items: flex-start;
      margin: 0;
      input{
        padding: 0;
        margin: 0;
        margin-right: 12px;
        border: 0;
      }
      a{
        color: $white;
      }
    }
    button{
      width: calc(100% - 32px);
    }
  }
}

.tq-page{
  padding: 80px 0;
  .container{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    >.box{
      width: 500px;
      max-width: 100%;
      padding: 50px 30px;
      border:1px solid rgba(255,255,255,.2);
      border-radius: 8px;
      background-color:rgba(255,255,255,.1);

    }
  }
}
.pp-active{
  overflow: hidden;
  main, header, footer{ filter: blur(20px);}
  .payment-page #footer{
    filter: blur(0);
  }
}

.outer-wrap.bx-ar{
  footer .footer-top .customer-care .list-wrap .each, #footer .footer-top .customer-care .list-wrap .each{
    padding-right: 15px;
    padding-left: 0;
    img{
      left: auto;
      right: 0;
    }
  }
  section.payment-page .pp-close{
    left: 15px;
    right: auto;
  }
}

.cctqbox{
  a.btn{
    text-decoration: none;
    height: auto;
    width: auto;
    display: inline;
    &::before, &::after{
      content:none;
    }
  }
}